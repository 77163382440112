import { useAuth } from './useAuth';
import { msalInstance, loginRequest } from '../authConfig';
import axios from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { env } from '../env';

export function useApi() {
  const { getUser, updateUser } = useAuth();
  const api = axios.create({
    baseURL: env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const isTokenExpired = tokenExpirationTime => {
    return tokenExpirationTime < Date.now();
  };

  const refreshToken = async user => {
    if (isTokenExpired(user.refreshToken)) {
      const { token, ...account } = user;
      const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account });

      const _user = {
        token: response.accessToken,
        refreshToken: Date.parse(response.expiresOn) - 5 * 60000,
        ...response.account,
      };

      console.log('REFRESH', response.accessToken);
      localStorage.setItem('user', JSON.stringify(_user));
      updateUser(_user);

      return _user?.token;
    }
    return user?.token;
  };

  api.interceptors.request.use(async config => {
    const user = getUser();

    if (user && user?.token) {
      const accessToken = await refreshToken(user);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  const feed = async () => {
    const user = getUser();

    let headers = {};

    if (user && user?.token) {
      const accessToken = await refreshToken(user);
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    // Initialize the SSE connection
    const eventSource = new EventSourcePolyfill(api.getUri({ url: 'v1/feed/notifications' }), {
      headers,
    });

    return eventSource;
  };

  return { api, feed };
}
